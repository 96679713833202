import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import utils from "../../services/utils";
import constants, { SEARCH_PARAM_DEPARTMENT } from "../../constants";
import "./ConversationsSearchCriteria.css";
import "./ConversationsSearchCriteria.css";
//import 'antd/dist/antd.css';
import { DatePicker } from "antd";
import {
  ListItemButton,
  ListItemText,
  Slider,
  Button,
  Box,
  Grid,
  Typography,
  Collapse,
} from "@mui/material";
import CSelectMultipleField from "../common/CSelectMultipleField";
import CTextField from "../common/CTextField";
import CDateField from "../common/CDateField";
import Tune from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const ConversationsSearchCriteria = (props) => {
  const {
    channels,
    role,
    divisions,
    titles,
    departments,
    region,
    locations,
    agents,
    convDirection,
    bussinessUnit,
    organization,
    datasource
  } = props;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ani, setANI] = useState();
  const [dnis, setDNIS] = useState();
  const [conversationID, setConversationID] = useState();
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [organizationValue, setOrganizationValue] = useState([]);
  const [selectedConvDirection, setSelectedConvDirection] = useState([]);
  const [poid, setPOID] = useState([]);
  const [extension, setExtension] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [duration, setDuration] = useState([]);
  const [organizationName, setOrganizationName] = useState([]);
  const [agentId, setAgentId] = useState([]);
  const [datasourceName, setDatasourcename] = useState([]);

  const [startSearch, setStartSearch] = useState(false);
  const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date
  const [additionalSearchToggle, setAdditionalSearchToggle] =
    React.useState(false);
  const [fields, setFields] = useState({
    start: "",
    end: "",
    ani: "",
    poid: "",
    extension: "",
    agentName: "",
    divisions: [],
    selectedConvDirections: [],
    conversationId: "", //contactId
    dnis: "",
    duration: "",
    agentId: "",
    supervisorName: "",
    bussinessUnit: "",
    organization: "",
    datasource : ""
  });

  const [searchDisabled, setSearchDisabled] = useState(true);
  const [operator, setOperator] = useState(">");

  const isDateRangeIncomplete = !fields.start || !fields.end;
  const [durationValue, setDurationValue] = React.useState([
    0,
    constants.MAX_CONVERSATION_DURATION_VALUE,
  ]);

  function onSelectDate(dateArray, dateString) {
    console.log(`onSelectDate value is ${JSON.stringify(dateString)}`);
    setdateRange(dateString);
  }

  useEffect(() => {
    try {
      const searchCriteriaString = sessionStorage.getItem(
        constants.R2S_SEARCH_CRITERIA
      );
      if (!searchCriteriaString) return;

      const searchCriteriaTemp = JSON.parse(searchCriteriaString);
      console.log(
        `ConversationsSearchCriteria::: got search criteria`,
        searchCriteriaTemp
      );

      // Utility function to handle setting dates
      const handleDateSetting = (dateString, setDateFunction) => {
        if (!dateString) return;
        const date = new Date(dateString);
        console.log(`Setting date time as:`, {
          dateString,
          date,
          type: typeof date,
        });
        setDateFunction(date);
      };

      // Set dates
      handleDateSetting(searchCriteriaTemp.startDateTime, setStartDate);
      handleDateSetting(searchCriteriaTemp.endDateTime, setEndDate);

      // Utility function to handle setting selected options
      const handleSelection = (
        criteria,
        allOptions,
        setFunction,
        allValue = "*"
      ) => {
        if (!criteria) return;
        let selectedOptions;
        if (criteria === allValue) {
          selectedOptions = allOptions.filter((x) => x.value !== allValue);
        } else {
          const criteriaArray = criteria.split(",");
          selectedOptions = allOptions.filter((x) =>
            criteriaArray.includes(x.value)
          );
        }
        console.log(`Setting criteria for:`, { criteria, selectedOptions });
        setFunction(selectedOptions);
      };

      // Set agents, divisions, and convDirection
      handleSelection(searchCriteriaTemp.agent, agents, setSelectedAgents);

      handleSelection(
        searchCriteriaTemp.division,
        divisions,
        setSelectedDivisions
      );
      handleSelection(
        searchCriteriaTemp.convDirection,
        convDirection,
        setSelectedConvDirection
      );

      handleSelection(
        searchCriteriaTemp.organization,
        organization,
        setOrganizationValue
      );

      // Set ANI, DNIS, and ConversationID
      setANI(searchCriteriaTemp.ani || "");
      setDNIS(searchCriteriaTemp.dnis || "");
      setConversationID(searchCriteriaTemp.conversationID || "");

      //set poid extension duration
      setPOID(searchCriteriaString.poid || "");
      setExtension(searchCriteriaString.extension || "");
      setDuration(searchCriteriaString.duration || "");

      //set agentId emplyeegroup datasourcename
      setAgentId(searchCriteriaString.agentId || "");
      setDatasourcename(searchCriteriaString.datasourceName || "")

    } catch (error) {
      console.error("conversationsSearchCriteria.useEffect.error", error);
    }
  }, []);

  const search = async () => {
    setStartSearch(true);
    // Early return if start or end dates are missing
    if (!fields.start || !fields.end) {
      console.log("Start or end date is missing.");
      return;
    }

    const searchCriteria = new Map();

    // Utility function for setting date criteria
    const setDateCriteria = (dateField, param, isStart) => {
      if (dateField) {
        let tempDate = moment(dateField, "YYYY-MM-DD").set({
          hour: isStart ? 0 : 23,
          minute: isStart ? 0 : 59,
        });
        searchCriteria.set(param, tempDate.toISOString());
      }
    };

    const setSelectionCriteria = (selectedItems, allItems, param) => {
      // Retrieve and format the user role from storage
      const roleFromStorage =
        sessionStorage.getItem(constants.R2S_USER_ROLE)?.toLowerCase() || "";
      const isAdmin = roleFromStorage === "admin";
      const isUser = roleFromStorage === "user";

      // Determine the selection status
      const isSelectedAll = selectedItems?.length === allItems.length;
      const isSelectedNone = selectedItems.length === 0;
      let criteriaValue;

      const getCriteriaValueForAll = () =>
        allItems.map((item) => item.value).join(",");

      if (allItems?.length === 1) {
        criteriaValue = getCriteriaValueForAll();
        searchCriteria.set(param, criteriaValue);
        return;
      }

      // Helper functions to get criteria values
      const getCriteriaValueForSelected = () => {
        if (!selectedItems) {
          return getCriteriaValueForAll();
        }
        return selectedItems.map((item) => item.value).join(",");
      };

      if (isAdmin) {
        criteriaValue =
          isSelectedAll || isSelectedNone ? "*" : getCriteriaValueForSelected();
      } else if (isUser) {
        criteriaValue =
          isSelectedAll || isSelectedNone
            ? getCriteriaValueForAll()
            : getCriteriaValueForSelected();
      } else {
        if (isSelectedAll || isSelectedNone) {
          criteriaValue =
            param === constants.SEARCH_PARAM_DIVISION
              ? getCriteriaValueForSelected()
              : "*";
        } else {
          criteriaValue = getCriteriaValueForSelected();
        }
      }
      // Decode the criteriaValue if it is URI-encoded
      criteriaValue = decodeURIComponent(criteriaValue);

      // Set the search criteria
      searchCriteria.set(param, criteriaValue);
    };

    // Set criteria for various fields
    setDateCriteria(fields.start, constants.SEARCH_PARAM_STARTDT, true);
    setDateCriteria(fields.end, constants.SEARCH_PARAM_ENDDT, false);
    setSelectionCriteria(
      fields.bussinessUnit,
      divisions,
      constants.SEARCH_PARAM_DIVISION
    );
    setSelectionCriteria(
      fields.agentName,
      agents,
      constants.SEARCH_PARAM_AGENT
    );

    setSelectionCriteria(
      fields.selectedConvDirections,
      convDirection,
      constants.SEARCH_PARAM_DIRECTION
    );

    setSelectionCriteria(
      fields.organization,
      organization,
      constants.SEARCH_PARAM_ORGANIZATION
    );

    setSelectionCriteria(
      fields.datasource,
      datasource,
      constants.SEARCH_PARAM_DATASOURCENAME
    );

    // Set criteria for other fields (refactored to reduce redundancy)
    const fieldParams = [
      { key: "ani", param: constants.SEARCH_PARAM_ANI },
      { key: "dnis", param: constants.SEARCH_PARAM_DNIS },
      { key: "conversationId", param: constants.SEARCH_PARAM_CONVERSATIONID },
      { key: "poid", param: constants.SEARCH_PARAM_POID },
      { key: "duration", param: constants.SEARCH_PARAM_DURATION },
      { key: "extension", param: constants.SEARCH_PARAM_EXTENSION },
      { key: "agentId", param: constants.SEARCH_PARAM_AGENTID },
      { key: "datasourceName", param: constants.SEARCH_PARAM_DATASOURCENAME },
    ];

    fieldParams.forEach(({ key, param }) => {
      if (fields[key]) {
        searchCriteria.set(param, decodeURIComponent(fields[key]));
      }
    });

    try {
      const searchParams = utils.mapToObj(searchCriteria);
      console.log("Performing search with parameters:", searchParams);
      props.search(searchParams);
      sessionStorage.setItem(
        constants.R2S_SEARCH_CRITERIA,
        JSON.stringify(searchParams)
      );
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleChangeStartDate = (newStartMoment) => {
    let updatedFields = { ...fields };

    console.log("handleChangeStartDate ", newStartMoment);

    if (newStartMoment && newStartMoment.isValid()) {
      if (fields.start && moment.isMoment(fields.start)) {
        newStartMoment.set({
          hour: fields.start.hour(),
          minute: fields.start.minute(),
        });
      }

      let endMoment = moment.isMoment(fields.end)
        ? moment(fields.end)
        : moment(newStartMoment).add(1, "days");
      if (!fields.end || endMoment.isBefore(newStartMoment)) {
        endMoment = moment(newStartMoment).add(1, "days");
      }

      updatedFields = {
        ...updatedFields,
        start: newStartMoment,
        end: endMoment,
      };
    }

    setFields(updatedFields);
    checkSearchEnabled(updatedFields);
  };

  const handleChangeEndDate = (newEndMoment) => {
    let updatedFields = { ...fields };

    if (newEndMoment && newEndMoment.isValid()) {
      let startMoment = moment.isMoment(fields.start)
        ? moment(fields.start)
        : moment();
      if (newEndMoment.isSameOrBefore(startMoment, "day")) {
        newEndMoment = moment(startMoment).add(1, "days");
      }

      updatedFields = { ...updatedFields, end: newEndMoment };
    }

    setFields(updatedFields);
    checkSearchEnabled(updatedFields);
  };

  const checkSearchEnabled = (updatedFields) => {
    // Search is enabled only if both start and end dates are valid
    const isSearchEnabled =
      updatedFields.start &&
      updatedFields.start.isValid() &&
      updatedFields.end &&
      updatedFields.end.isValid();
    setSearchDisabled(!isSearchEnabled);
  };

  const handleChangeDirections = (event, value) => {
    setFields({
      ...fields,
      selectedConvDirections: value.map((v) => {
        return { name: v.label, value: v.value };
      }),
      selectedConvDirectionsValue: value,
    });
    return;
  };

  const handleChangeAni = (event, value) => {
    setFields({
      ...fields,
      ani: event.target.value,
    });
    return;
  };

  const handleChangeExtension = (event, value) => {
    setFields({
      ...fields,
      extension: event.target.value,
    });
    return;
  };

  const handleChangePOID = (event, value) => {
    setFields({
      ...fields,
      poid: event.target.value,
    });
    return;
  };

  const handleChangeDNIS = (event, value) => {
    setFields({
      ...fields,
      dnis: event.target.value,
    });
    return;
  };

  const handleChangeOrganization = (event, value) => {
    console.log("value : ", value);
    setFields({
      ...fields,
      organization: value.map((v) => {
        return { name: v.label, value: v.value };
      }),
      organizationValue: value,
    });
    return;
  };

  const handleChangeDatasource = (event, value) => {
    console.log("value : ", value);
    setFields({
      ...fields,
      datasource: value.map((v) => {
        return { name: v.label, value: v.value };
      }),
      datasourceValue: value,
    });
    return;
  };


  const handleChangeAgentId = (event, value) => {
    setFields({
      ...fields,
      agentId: event.target.value,
    });
    return;
  };

  const handleChangeConversationId = (event, value) => {
    setFields({
      ...fields,
      conversationId: event.target.value,
    });
    return;
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleDurationChange = (event, newValue) => {
    setDurationValue(newValue);
    console.log(newValue);

    // Convert each value in the newValue array from minutes to seconds
    const durationInSeconds = newValue.map((value) => value * 60);

    // Join the converted values with a hyphen
    const durationString = durationInSeconds.join("-");

    setFields({
      ...fields,
      duration: durationString,
    });
  };

  const handleAdditionalSearchToggle = (event) => {
    event.preventDefault();
    setAdditionalSearchToggle(!additionalSearchToggle);
  };

  const isValidDivision = (value) => {
    return (
      value && isNaN(value) && typeof value === "string"
    );
  };

  // Filter the divisions array before passing it to the MultiSelect component
  const validDivisions = divisions.filter((division) =>
    isValidDivision(division.value)
  );

  const selectStyle = {
    width: "30%",
    marginRight: "5%",
    height: "40px", // Adjust to match the height of other fields
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: "8px", // Align with other fields
  };

  const textFieldStyle = {
    width: "65%",
    height: "40px", // Adjust to match the height of other fields
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginTop: "8px", // Align with other fields
  };

  const handleChangeAgents = (event, value) => {
    console.log("handleChangeAgents", value);

    setFields({
      ...fields,
      agentName: value.map((v) => {
        return { name: v.label, value: v.value };
      }),
      agentsValue: value,
    });

    return;
  };

  const handleChangeDivisions = (event, value) => {
    console.log("handleChangeDivisions", value);
    setFields({
      ...fields,
      bussinessUnit: value.map((v) => {
        return { name: v.label, value: v.value };
      }),
      bussinessUnitValue: value,
    });
    console.log(fields);
    return;
  };

  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={search}
        sx={{ padding: "6px", backgroundColor: "#f5f5f5" }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Disclaimer Alert */}
          {searchDisabled && (
            <Grid item xs={12}>
              <Box
                borderLeft={4}
                borderColor="warning.main"
                bgcolor="background.paper"
                p={0.1}
                my={0.1}
              >
                <Typography variant="body1" color="textPrimary">
                  <strong>&nbsp;&nbsp;Attention: </strong>Ensure that you have
                  entered both the start and end dates.
                </Typography>
              </Box>
            </Grid>
          )}

          {/* Start Date Field */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              component="label"
              htmlFor="start-date"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              Start Date
            </Typography>
            <CDateField
              formFields={fields}
              fieldName={"start"}
              handler={handleChangeStartDate}
              id="start-date"
            />
          </Grid>

          {/* End Date Field */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              component="label"
              htmlFor="end-date"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              End Date
            </Typography>
            <CDateField
              formFields={fields}
              fieldName={"end"}
              handler={handleChangeEndDate}
              id="end-date"
            />
          </Grid>

          {/* ANI Field */}
          <Grid item xs={12} sm={6} md={1}>
            <Typography
              component="label"
              htmlFor="ani-field"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              ANI
            </Typography>
            <CTextField
              formFields={fields}
              fieldName={"ani"}
              handler={handleChangeAni}
              id="ani-field"
              maxLength={15}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={1}>
            <Typography
              component="label"
              htmlFor="poid-field"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              POID
            </Typography>
            <CTextField
              formFields={fields}
              fieldName={"pod"}
              handler={handleChangePOID}
              id="pod-field"
              maxLength={15}
            />
          </Grid>

          {/* Extension Field */}
          <Grid item xs={12} sm={6} md={1}>
            <Typography
              component="label"
              htmlFor="extension-field"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              Extension
            </Typography>
            <CTextField
              formFields={fields}
              fieldName={"extension"}
              handler={handleChangeExtension}
              id="extension-field"
              maxLength={15}
            />
          </Grid>

          {/* Agent Name Field */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              component="label"
              htmlFor="agent-name-field"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              Agent Name
            </Typography>
            <CSelectMultipleField
              formFields={fields}
              fieldName={"agents"}
              fieldOptions={agents}
              handler={handleChangeAgents}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              component="label"
              htmlFor="business-unit-field"
              variant="subtitle2"
              color="textSecondary"
              sx={{ display: "block", marginBottom: "8px" }}
            >
              Business Unit
            </Typography>
            <CSelectMultipleField
              formFields={fields}
              fieldName={"bussinessUnit"}
              fieldOptions={validDivisions}
              handler={handleChangeDivisions}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              disabled={searchDisabled}
              onClick={search}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <ListItemButton onClick={handleAdditionalSearchToggle}>
          <ListItemIcon>
            <Tune />
          </ListItemIcon>
          <ListItemText primary="Additional Search Criteria" />
          {additionalSearchToggle ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>

        {/* Direction Field */}
        {/*<Collapse in={showAdditionalFilters} timeout="auto" unmountOnExit>*/}
        <Collapse in={additionalSearchToggle} timeout="auto">
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ paddingTop: "8px" }}
          >
            <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Direction
              </Typography>
              <CSelectMultipleField
                formFields={fields}
                fieldName={"selectedConvDirections"}
                fieldOptions={convDirection}
                handler={handleChangeDirections}
              />
            </Grid>
            {/* Conversation Id Field */}
            <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                htmlFor="conversation-id-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Contact Id
              </Typography>
              <CTextField
                formFields={fields}
                fieldName={"conversationId"}
                handler={handleChangeConversationId}
                id="conversation-id-field"
              />
            </Grid>

            {/* DNIS Field */}

            <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                htmlFor="duration-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Duration (in minutes)
              </Typography>
              <Slider
                value={durationValue}
                onChange={handleDurationChange}
                valueLabelDisplay="auto"
                min={0}
                max={constants.MAX_CONVERSATION_DURATION_VALUE}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography
                component="label"
                htmlFor="dnis-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                DNIS
              </Typography>
              <CTextField
                formFields={fields}
                fieldName={"dnis"}
                handler={handleChangeDNIS}
                id="dnis-field"
                maxLength={15}
              />
            </Grid>

            {/* Organization Field */}
            <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                htmlFor="organization-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Organization
              </Typography>
              <CSelectMultipleField
                formFields={fields}
                fieldName={"organization"}
                fieldOptions={organization}
                handler={handleChangeOrganization}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                htmlFor="datasource-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Data Source
              </Typography>
              <CSelectMultipleField
                formFields={fields}
                fieldName={"datasource"}
                fieldOptions={datasource}
                handler={handleChangeDatasource}
              />
            </Grid>

            {/* Supervisor Name Field */}
            {/* <Grid item xs={12} sm={6} md={2}>
              <Typography
                component="label"
                htmlFor="supervisor-name-field"
                variant="subtitle2"
                color="textSecondary"
                sx={{ display: "block", marginBottom: "8px" }}
              >
                Supervisor Name
              </Typography>
              <CTextField
                formFields={fields}
                fieldName={"supervisorName"}
                handler={handleChangeSupervisorName}
                id="supervisor-name-field"
              />
            </Grid> */}
          </Grid>
        </Collapse>
      </Box>
    </React.Fragment>
  );
};

export default ConversationsSearchCriteria;
